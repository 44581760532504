<template>
    <app-wrapper>
        <template v-slot:child>
            <!--Customer Transactions Detail -->
            <div class="w-90 center pt3 font-w1">
                <!--        &lt; Inventory-->
                <router-link :to="{ name: 'Services' }"> &lt; Service </router-link>
            </div>
            <div class="w-90 center">
                <div class="flex flex-wrap justify-between items-center pv3 box-border-bottom">
                    <div>
                        <div class="pb3 pb0-l f3 b">Service details</div>
                    </div>
                    <div class="scrollmenu" v-if="role === 'admin' || role === 'owner'">
                        <button class="btn3" disabled>Archive Service</button>

                        <router-link v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(36)"
                            :to="{ name: 'ProductUpdate' }">
                            <button class="btn2 ml2">Edit Service</button>
                        </router-link>
                    </div>
                </div>
            </div>
            <!--Customer Transactions Detail -->

            <template v-if="productLoading || !state.serviceDetails?.service">
                <Spinner />
            </template>
            <template v-else>
                <!-- customer details, full name, phone-->
                <div class="w-90 center flex flex-wrap pt4">
                    <div class="w-25-l w-100 box-border pv4 tc">
                        <div class="flex justify-center items-center">
                            <div class="flex justify-center items-center font-w3 box-border1" style="
                                    background: rgb(215, 222, 250);
                                    height: 160px;
                                    width: 160px;
                                    border-radius: 50%;
                                    font-size: 55px;
                                    color: rgb(19, 44, 140);
                                    margin-bottom: 20px;
                                    overflow: hidden;
                                ">
                                <img :src="
                                        state.serviceDetails?.service?.imageUrl ||
                                            require('@/assets/images/inventory-default.png')
                                    " class="pr1 h-100 w-100 cover" :alt="state?.serviceDetails?.service?.name"
                                    style="object-position: center" />

                                <!--     style="object-position: center; height: 90%; width: 90%"-->
                                <!--  {{ `${productDetails?.product?.name?.split(' ')?.[0]?.split('')?.[0]?.toUpperCase() || ''}` }}-->
                            </div>
                        </div>

                        <div class="pt3 b" style="color: #193ab8; font-size: 22px">{{ state.serviceDetails?.service.name
                            }}</div>
                        <!-- <img src="../../assets/images/table-shelf.png" alt="" /> -->
                        <!-- <div class="pt3 b" style="color: #132C8C;">Table Shelf</div> -->
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Service Name</div>
                            <div>{{ state.serviceDetails?.service.name }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Service Ref</div>
                            <div>{{ state.serviceDetails?.service.sku }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2 ttc" style="color: #55596ebf">Service ID</div>
                            <div>{{ state.serviceDetails?.service._id?.toUpperCase() }}</div>
                        </div>
                        <!-- <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Size</div>
                            <div>{{state.serviceDetails?.service.size }}</div>
                        </div> -->
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Category</div>
                            <div>{{ state.serviceDetails?.service.categoryName || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Brand</div>
                            <div>{{ state.serviceDetails?.service.brandName || '-' }}</div>
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Variants</div>
                            <div>{{ formatQuantity(productDetails?.service?.variant) || 1 }}</div>
                        </div>
                        <!-- <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Color</div>
                            <div>{{state.serviceDetails?.service.color || '-' }}</div>
                        </div> -->
                    </div>

                    <div class="w-25-l w-100 box-border pa4">
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Suppliers</div>
                            <div v-if="productDetails?.suppliers?.length" class="flex" style="gap: 0.3rem">
                                <template v-for="supplier of productDetails?.suppliers" :key="supplier">
                                    <span class="box-border1 pa1 b font-w1">
                                        <router-link :to="{ name: 'Entity', params: { id: supplier?.entity } }">
                                            {{ supplier?.nameOrCompany }}
                                        </router-link>
                                    </span>
                                </template>
                            </div>

                            <span v-else class="mr2">No suppliers found</span>

                            <!--     <template v-if="state?.suppliers">-->
                            <!--     <template v-for="supplier of state?.suppliers" v-bind:key="supplier">-->
                            <!--           <span class="mr2">{{ supplier || '-' }}, </span>-->
                            <!--    </template>-->
                            <!--      </template>-->

                            <!--    <template v-else v-for="supplier ofstate.serviceDetails?.service.suppliers" v-bind:key="supplier + 1">-->
                            <!--   <span class="mr2">{{ supplier.company || supplier.supplier || '-' }}, </span>-->
                            <!--   </template>-->
                        </div>
                        <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Retail Price (Avg.)</div>
                            <div>
                                {{ formatAmount(state.serviceDetails?.service.unitPrice,
                                $store.state.Settings?.currency) }}
                                (
                                {{
                                formatAmount(state.serviceDetails?.service.averageCostPrice,
                                $store.state.Settings?.currency)
                                }})
                            </div>
                        </div>
                        <!-- <div class="pv2">
                            <div class="pb2" style="color: #55596ebf">Retail Price (Avg.)</div>
                            <div>
                                {{ formatAmount(state.serviceDetails?.service.totalSalesPrice) }}
                                ( {{ formatAmount(state.serviceDetails?.service.averageSalesPrice) }})
                            </div>
                        </div> -->
                        <!--          <div class="pv2">-->
                        <!--            <div class="pb2" style="color: #55596ebf">Recommended Price</div>-->
                        <!--            <div>{{ formatAmount(productDetails?.summary?.recommendedSellPrice || 0) || '-' }}</div>-->
                        <!--          </div>-->
                    </div>
                </div>
                <div class="w-90 center">
                    <div class="pa4 box-border">
                        <div class="pb2 b">Service Description</div>
                        <div class="lh-copy">
                            {{ state.serviceDetails?.service.description }}
                        </div>
                    </div>
                </div>
                <!-- customer details, full name, phone-->

                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->
                <!-- <div class="w-90 center mv4">
                    <div class="flex flex-wrap adj-text font-w1">
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">Current Stock On Hand</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.product?.currStockOnHandQty) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">Stock On Hand Purchase Value</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatAmount(productDetails?.product?.stockOnHandPurchaseValue) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">Stock On Hand Sales Value</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatAmount(productDetails?.product?.stockOnHandSalesValue) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/revenue.png" alt="" />
                                <div class="pl2 lh-title ttc">Average Inventory Day</div>
                            </div>
                            <div class="f4-l f6 b">Coming Soon...</div>
                        </div>
                    </div>
                </div> -->
                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->

                <!-- total cost, total qty sold, average price day, total sales, average cost -->
                <div class="w-90 center pt4">
                    <div class="flex flex-wrap adj-text font-w1">
                        <!-- <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-cost.png" alt="" />
                                <div class="pl2 lh-title">Total Qty. purchased</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.product?.totalQtyPurchased) }}</div>
                        </div> -->
                        <!-- <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-qty.svg" alt="" />
                                <div class="pl2 lh-title">Total Cost Of Purchase</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatAmount(productDetails?.product?.totalCostPrice) }}</div>
                        </div> -->
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-cost.png" alt="" />
                                <div class="pl2 lh-title">Total Qty. sold</div>
                            </div>
                            <div class="f4-l f6 b">{{ formatQuantity(productDetails?.service?.totalQtySold) }}</div>
                        </div>
                        <div class="w-25-l w-50 pa3 box-border">
                            <div class="flex items-center pb3">
                                <img src="../../assets/images/total-qty.svg" alt="" />
                                <div class="pl2 lh-title">Total Sales</div>
                            </div>
                            <div class="f4-l f6 b">
                                {{ formatAmount(productDetails?.service?.totalSalesPrice,
                                $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <!--                        <div class="w-20-l w-50 pa3 box-border">-->
                        <!--                            <div class="flex items-center pb3">-->
                        <!--                                <img src="../../assets/images/total-sale.svg" alt="" />-->
                        <!--                                <div class="pl2">Average Cost</div>-->
                        <!--                            </div>-->
                        <!--                            <div class="f3 b">$441</div>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <!-- total cost, total qty sold, average price day, total sales, average cost -->

                <!-- stock on hand value, current stock on hand , total qty purchased, average inventory -->

                <!-- sales trend count & volume -->

                <!--      TODO: Graphs-->
                <!--      <div class="flex flex-wrap justify-between w-90 center mt4">-->
                <!--        <div class="w-60-l w-100 box-border mb0-l mb4">-->
                <!--          <div class="">-->
                <!--            <div class="flex justify-between items-center pa3 box-border-bottom">-->
                <!--              <p class="b">Sales trend-count</p>-->
                <!--              <p>-->
                <!--                <select name="" id="" style="padding: 5px">-->
                <!--                  <option value="">Next 30days</option>-->
                <!--                  <option value="">Next 7days</option>-->
                <!--                </select>-->
                <!--              </p>-->
                <!--            </div>-->
                <!--          </div>-->

                <!--          <div class="">-->
                <!--            <div class="pa3">-->
                <!--              <div class="flex justify-between items-center">-->
                <!--                <p class="w-20">248,500</p>-->
                <!--                <div class="flex items-center">-->
                <!--                  <p class="pl2 flex items-center">12.6% <img src="../../assets/images/increase-bg.svg" alt="" /></p>-->
                <!--                  <p class="pl1">Last month 230,000</p>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <img src="../../assets/images/sales-trend-graph.png" class="pt4" alt="" />-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
                <!--        <div class="w-34-l w-100 box-border">-->
                <!--          <div class="">-->
                <!--            <div class="flex justify-between items-center pa3 box-border-bottom">-->
                <!--              <p class="b">Sales trend-volume</p>-->
                <!--              <p>-->
                <!--                <select name="" id="" style="padding: 5px">-->
                <!--                  <option value="">Next 30days</option>-->
                <!--                  <option value="">Next 7days</option>-->
                <!--                </select>-->
                <!--              </p>-->
                <!--            </div>-->
                <!--          </div>-->

                <!--          <div class="">-->
                <!--            <div class="pa3">-->
                <!--              <div class="flex justify-between">-->
                <!--                <p class="w-20">248,500</p>-->
                <!--                <div class="flex items-center">-->
                <!--                  <p class="pl2 flex items-center">12.6% <img src="../../assets/images/increase-bg.svg" alt="" /></p>-->
                <!--                  <p class="pl1">Last month 230,000</p>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <img src="../../assets/images/sale-volume-graph.png" class="pt4" alt="" />-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
                <!--      </div>-->
                <!-- sales trend count & volume -->

                <div class="w-90 center mv5">
                    <div class="flex flex-wrap justify-between items-center"
                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee">
                        <!--                <Filter />-->
                    </div>
                </div>

                <!-- desktop content display -->
                <div class="scrollmenu w-90 center">
                    <table class="w-100 tl" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="pb3 pr3 box-border-bottom tl">Reference No</th>
                                <th class="pb3 pr3 box-border-bottom tl">Date</th>
                                <th class="pb3 pr3 box-border-bottom tl">Transaction Type</th>
                                <th class="pb3 box-border-bottom tl pr2">Entity</th>
                                <th class="pb3 pr3 box-border-bottom tl">Quantity</th>
                                <th class="pb3 pr3 box-border-bottom tl">Cost per item</th>
                                <th class="pb3 box-border-bottom tl">Total Cost</th>
                            </tr>
                        </thead>
                        <template v-for="transaction of productDetails?.transactions" v-bind:key="transaction._id">
                            <tr class="font-w1">
                                <td class="pv3 pr3 box-border-bottom tl">{{ transaction?.reference }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatDate(transaction?.date || transaction?.createdAt) }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl flex items-center ttc">
                                    <img src="../../assets/images/file.svg" class="pr1" alt="" />
                                    {{
                                    transaction.subtype === 'damaged_stock'
                                    ? 'Damaged Stock'
                                    : transaction.subtype === 'missing_stock'
                                    ? 'Missing Stock'
                                    : transaction.subtype === 'promotion_stock'
                                    ? 'Promotion Stock'
                                    : transaction.type
                                    }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ transaction?.entityName }}</td>
                                <td class="pv3 pr3 box-border-bottom tl">{{ formatQuantity(transaction?.quantity) }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(transaction?.unit_price, $store.state.Settings?.currency) }}
                                </td>
                                <td class="pv3 pr3 box-border-bottom tl">
                                    {{ formatAmount(transaction?.amount, $store.state.Settings?.currency) }}
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </template>
        </template>
        <!-- pagination -->
        <!--            <Pagination />-->
        <!-- pagination -->
    </app-wrapper>
</template>

<script>
    import { onMounted, computed, reactive, watch } from 'vue'
    import { useStore } from 'vuex'
    import { useRoute } from 'vue-router'

    import AppWrapper from '@/layout/AppWrapper'
    // import Pagination from '@/components/Pagination'
    // import Filter from '@/components/Filter'
    import { formatAmount, formatDate, formatQuantity } from '@/utils/lib'
    import Spinner from '@/components/Spinner'

    export default {
        name: 'Service',
        // components: { AppWrapper, Pagination, Spinner },
        components: { AppWrapper, Spinner },

        setup() {
            const store = useStore()
            const route = useRoute()
            const productDetails = computed(() => store?.state?.Inventory?.serviceDetails)
            const productLoading = computed(() => store?.state?.Inventory?.serviceLoading)
            const role = computed(() => store?.state?.Auth?.role)

            const state = reactive({
                serviceDetails: {},
                suppliers: [],
                company: ''
            })

            watch(
                () => productDetails.value,
                (prevValue, currValue) => {
                    if (prevValue !== currValue) {
                        console.log(currValue, 'currValue', prevValue, 'prevValue')
                        state.serviceDetails = prevValue

                        console.log(state.serviceDetails, 'state.serviceDetails')

                        state.suppliers = state.serviceDetails?.service?.suppliers?.filter(
                            (value, index, self) => self.indexOf(value) === index
                        )
                        state.suppliers = state?.suppliers?.reduce((acc, currValue) => {
                            acc.push(currValue.company || currValue.suppliers)
                            return acc
                        }, [])

                        state.suppliers = state.suppliers?.filter((value, index, self) => self.indexOf(value) === index)
                    }
                }
            )

            onMounted(() => {
                const { id } = route.params
                store.dispatch('Inventory/getService', id)
            })

            return {
                state,
                productDetails,
                productLoading,
                formatDate,
                formatAmount,
                formatQuantity,
                role
            }
        }
    }
</script>

<style scoped></style>